import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import UserAreaMenu from "../components/userAreaMenu"
import { getCurrentUser } from "../services/authService"
import { anonymousUser } from "../config.json"

class AreaClienti extends Component {
  state = { user: { ...anonymousUser }, loading: true }

  async componentDidMount() {
    const user = await getCurrentUser()
    if (user.id === 0) {
      window.location = "/login"
    }
    this.setState({ user, loading: false })
  }

  render() {
    const { user } = this.state
    return (
      <Layout>
        <SEO
          title="Area Clienti"
          keywords={[`vision care`]}
          description="Area Clienti"
        />
        <div className="container">
          <div className="row">
            <div className="col-12 pt-3">
              <h1 className={"page-title"}>Area Clienti</h1>

              <div className="row">
                {/*  LEFT */}
                <div className="col-12">
                  <UserAreaMenu menuItemSelected="Dashboard" />
                </div>

                {/*  CENTER */}
                <div className="col-12">
                  <div>
                    <h2 className={"customers-area-title"}>Dashboard</h2>

                    <p>
                      Benvenuto {user.attributes.full_name}! Ti trovi nell'area
                      clienti di Vision Care Italia!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default AreaClienti
